@import url("/extensions/fontawesome.css");
@font-face {
  font-family: Handlee Regular;
  src: url("../webfonts/Handlee-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: NixieOne Regular;
  src: url("../webfonts/NixieOne-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("../webfonts/Roboto-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("../webfonts/Roboto-Italic.ttf");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("../webfonts/Roboto-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("../webfonts/Roboto-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("../webfonts/Roboto-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("../webfonts/Roboto-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("../webfonts/Roboto-Black.ttf");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url("../webfonts/Roboto-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
